<template>
  <v-container>
    <mex-home-card
      :useHeaderImage="true"
      class="mt-15"
      headerCardColor="backgroundHome"
      headerColor="backgroundHome"
      headerImage="NoAccess.png"
      headerTitle="NO ACCESS"
    >
      <template v-slot:reveal-actions>
        <v-row align="center" justify="center">
          <div class="mx-2">
            <v-btn large @click="goToHome">
              <v-icon left>mdi-home</v-icon>
              Home
            </v-btn>
          </div>
          <div class="mx-2">
            <v-btn large @click="goToLogin">
              <v-icon left>mdi-fingerprint</v-icon>
              Login
            </v-btn>
          </div>
        </v-row>
      </template>
    </mex-home-card>
  </v-container>
</template>

<script>
export default {
  name: 'Logout',
  components: {
    MexHomeCard: () => import('../components/MedITEX_Vue_Components/MexComponents/MexHomeCard'),
  },
  created() {
    this.$store.dispatch('auth/logout').then(() => {
    });
  },
  methods: {
    goToLogin() {
      this.$router.push('/pages/login');
    },
    goToHome() {
      this.$router.push('/');
    },
  },
};
</script>
